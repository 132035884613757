var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "operproccess__coming"
    }
  }, [_c('egais-info-modal', {
    attrs: {
      "head": _vm.currentHead
    }
  }), _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('documents-header', {
    attrs: {
      "page_type": "outgoing"
    },
    on: {
      "date_range": _vm.updateDateRange
    }
  }), _c('div', {
    staticClass: "b-table__content"
  }, [_c('documents-table-filter', {
    ref: "doc_table_filter",
    attrs: {
      "table_name": "outgoing",
      "total": _vm.total,
      "selected_sum": _vm.selected_sum
    },
    on: {
      "handler_pagination": _vm.handlerSearch,
      "clear": _vm.clearSearchStr
    }
  }), _c('documents-table', {
    attrs: {
      "fields": _vm.fields,
      "items_total": _vm.items_total,
      "items": _vm.document_list,
      "table_is_busy": _vm.tableIsBusy,
      "get_select_all": _vm.getSelectAll,
      "get_selected_arr": _vm.getSelectedArr,
      "set_select_all": _vm.SET_SELECT_ALL,
      "set_selected": _vm.SET_SELECTED,
      "page_type": "outgoing"
    },
    on: {
      "select": _vm.setSelected,
      "showEgaisModal": _vm.showEgaisModal
    }
  })], 1), _c('table-navbar', {
    attrs: {
      "items": _vm.getSelectedArr,
      "clear_selected": _vm.clearSelected,
      "items_name": "documents",
      "doc_type": "outgoing"
    },
    on: {
      "remove_items": _vm.remove_items
    }
  }), _c('pagination', {
    attrs: {
      "total": _vm.items_total,
      "table_name": "outgoing"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }